import React, { useState } from 'react';
import { Box, TextField, styled, Chip, Button, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Grid, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Breadcrumb } from 'app/components';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import { Add, Delete, Edit, Settings } from '@mui/icons-material';
import api from 'app/services/api';
import { useContext } from 'react';
import { AlertContext } from 'app/contexts/AlertContext';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import * as dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
    '& .breadcrumb': {
        marginBottom: '30px',
        [theme.breakpoints.down('sm')]: { marginBottom: '16px' },
    },
}));

const ListagemProgramacaoDeFerias = () => {
    const navigate = useNavigate();
    const [search, setSearch] = useState('');
    const [loading, setLoading] = useState(false);
    const { setAlert } = useContext(AlertContext);

    const [itens, setItens] = useState([]);
    const [totalItens, setTotalItens] = useState(0);

    const carregarDadosFeriasColaboradores = async () => {
        try {
            setLoading(true)
            let { data } = await api.get('/PedidosDeFerias/DadosFeriasColaboradores');
            setItens(data)
        } catch (e) {
            setAlert({ open: true, message: 'Erro ao carregar registros', type: 'error' })
        } finally {
            setLoading(false)
        }
    }

    const calculaChipLimiteDeGozo = (limiteDeGozo) => {
        let color = 'default'
        if (!limiteDeGozo) {
            return <Chip label='Desconhecido' color={color} size="small"/>
        }

        let anoLimiteDeGozo = dayjs(limiteDeGozo, 'DD/MM/YYYY').year()
        let anoAtual = dayjs().year()

        if (anoLimiteDeGozo < anoAtual) {
            color = 'error'
        } else if (anoLimiteDeGozo === anoAtual) {
            color = 'success'
        } else {
            color = 'default'
        }

        return <Chip label={limiteDeGozo} color={color} size="small"/>
    }

    const calculaChipSituacao = (situacao) => {
       
        if (situacao == 'Férias Planejadas') {
            return <Chip label={situacao} color='success' size="small"/>
        }

        if (situacao == 'Férias Não Planejadas') {
            return <Chip label={situacao} color='error' size="small"/>
        }
    }

    const calculaChipLiberacaoDiretoria = (liberacaoDiretoria) => {
       
        if (liberacaoDiretoria == 'Aprovado') {
            return <Chip label={liberacaoDiretoria} color='success' size="small"/>
        }

        if (liberacaoDiretoria == 'Não Aprovado') {
            return <Chip label={liberacaoDiretoria} color='error' size="small"/>
        }

        return ''
    }

    const calculaChipSituacaoFerias = (situacaoFerias) => {
       
        if (situacaoFerias == 'Férias Gozadas') {
            return <Chip label={situacaoFerias} color='success' size="small"/>
        }

        if (situacaoFerias == 'Férias Vencidas') {
            return <Chip label={situacaoFerias} color='error' size="small"/>
        }

        if (situacaoFerias == 'Em Andamento') {
            return <Chip label={situacaoFerias} color='warning' size="small"/>
        }

        return <Chip label={situacaoFerias} color='default' size="small"/>
    }

    const calculaChipSituacaoRh = (situacaoRh) => {
       
        if (situacaoRh == 'Férias Concedidas') {
            return <Chip label={situacaoRh} color='success' size="small"/>
        }

        if (situacaoRh == 'Férias Vencidas') {
            return <Chip label={situacaoRh} color='error' size="small"/>
        }

        if (situacaoRh == 'Aguardando Liberação') {
            return <Chip label={situacaoRh} color='warning' size="small"/>
        }

        if (situacaoRh == 'Férias Não Planejadas') {
            return <Chip label={situacaoRh} color='secondary' size="small"/>
        }

        return ''
    }

    useEffect(() => {
        carregarDadosFeriasColaboradores()
    }, []);

    return (
        <Container>
            <Breadcrumb
                routeSegments={[
                    { name: 'Programação de Férias' }
                ]}
            />

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <TextField
                    sx={{ mt: 1, mb: 1 }}
                    id='search'
                    type='search'
                    label='Localizar'
                    placeholder='Digite aqui o que deseja encontrar'
                    value={search}
                    onChange={(e) => { setSearch(e.target.value) }}
                    onKeyDown={() => { }}
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                onClick={() => { }}
                            >
                                <SearchIcon fontSize='inherit' />
                            </IconButton>
                        )
                    }}
                    fullWidth
                />

                <Button variant='contained' color='primary'
                    sx={{ ml: 1, mt: 1, mb: 1, width: 250, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    onClick={() => {
                        navigate('/planejamento-de-ferias')
                    }}
                    startIcon={<Settings />}
                >
                    <Typography>Gerenciar Programação</Typography>
                </Button>
            </Box>

            <Grid item xs={12} sm={12} md={12} sx={{ mt: 5 }}>
                <TableContainer component={Paper} sx={{ px: 3}}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ width: '100px' }}>Matrícula</TableCell>
                                <TableCell sx={{ width: '400px' }}>Funcionário</TableCell>
                                <TableCell sx={{ width: '150px' }}>Loja</TableCell>
                                <TableCell sx={{ width: '200px' }}>Departamento</TableCell>
                                <TableCell sx={{ width: '100px' }}>Admissão</TableCell>
                                <TableCell sx={{ width: '100px' }}>Início Aq.</TableCell>
                                <TableCell sx={{ width: '100px' }}>Fim Aq.</TableCell>
                                <TableCell sx={{ width: '100px' }}>Limite de Gozo</TableCell>
                                <TableCell sx={{ width: '100px' }}>Início Fe.</TableCell>
                                <TableCell sx={{ width: '100px' }}>Fim Fe.</TableCell>
                                <TableCell sx={{ width: '100px' }}>Dias de Férias</TableCell>
                                <TableCell sx={{ width: '180px' }}>Situação</TableCell>
                                <TableCell sx={{ width: '180px' }}>Liberação Diretoria</TableCell>
                                <TableCell sx={{ width: '180px' }}>RH</TableCell>
                                <TableCell sx={{ width: '180px' }}>Status</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {itens.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{item.matricula}</TableCell>
                                    <TableCell sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{item.nome}</TableCell>
                                    <TableCell sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{item.empresa?.nome}</TableCell>
                                    <TableCell sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{item.departamento}</TableCell>
                                    <TableCell sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{item.dataAdmissao ? dayjs(item.dataAdmissao, 'YYYY-MM-DD').format('DD/MM/YYYY') : ''}</TableCell>
                                    <TableCell sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{item.inicioAquisicao}</TableCell>
                                    <TableCell sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{item.fimAquisicao}</TableCell>
                                    <TableCell sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{ calculaChipLimiteDeGozo(item.limiteGozo) }</TableCell>
                                    <TableCell sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{ item.inicioFerias }</TableCell>
                                    <TableCell sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{ item.fimFerias  }</TableCell>
                                    <TableCell sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{item.diasFerias}</TableCell>
                                    <TableCell sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{ calculaChipSituacao(item.situacao) }</TableCell>
                                    <TableCell sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{ calculaChipLiberacaoDiretoria(item.liberacaoDiretoria) }</TableCell>
                                    <TableCell sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{ calculaChipSituacaoRh(item.situacaoRh) }</TableCell>
                                    <TableCell sx={{whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden'}}>{ calculaChipSituacaoFerias(item.situacaoFerias) }</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>

        </Container>
    )
}

export default ListagemProgramacaoDeFerias