function weekDay(abbr) {
  const weekdays = [
    {
      abbr: 'Do',
      value: 'D'
    },
    {
      abbr: '2ª',
      value: 'S'
    },
    {
      abbr: '3ª',
      value: 'T'
    },
    {
      abbr: '4ª',
      value: 'Q'
    },
    {
      abbr: '5ª',
      value: 'Q'
    },
    {
      abbr: '6ª',
      value: 'S'
    },
    {
      abbr: 'Sá',
      value: 'S'
    }
  ];

  return weekdays.find((day) => day.abbr === abbr).value;
}

export default weekDay;