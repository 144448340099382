import { lazy } from 'react';
import Loadable from 'app/components/Loadable';

const Planos = Loadable(lazy(() => import('./Planos')));
const Plano = Loadable(lazy(() => import('./Plano')));

const planosRoutes = [
  { path: '/planos', element: <Planos /> },
  { path: '/plano', element: <Plano /> },
  { path: '/plano/:id', element: <Plano /> },
];

export default planosRoutes;
