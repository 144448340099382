import { lazy } from 'react';
import Loadable from 'app/components/Loadable';

const Paineis = Loadable(lazy(() => import('./Paineis')));
const Painel = Loadable(lazy(() => import('./Painel')));

const paineisRoutes = [
  { path: '/paineis', element: <Paineis /> },
  { path: '/painel', element: <Painel /> },
  { path: '/painel/:id', element: <Painel /> }
];

export default paineisRoutes;
