import { createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import { MatxLoading } from 'app/components';
import api from 'app/services/api';

const initialState = {
  user: null,
  isInitialised: false,
  isAuthenticated: false,
};

const isValidToken = (accessToken) => {
  if (!accessToken) return false;
  const decodedToken = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;  
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    // api.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    api.defaults.headers.common.Authorization = accessToken;
  } else {
    localStorage.removeItem('accessToken');
    delete api.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'INIT': {
      const { isAuthenticated, user } = action.payload;
      return { ...state, user, isAuthenticated, isInitialised: true };
    }
    case 'LOGIN': {
      const { user } = action.payload;
      return { ...state, user, isAuthenticated: true };
    }
    case 'LOGOUT': {
      return { ...state, isAuthenticated: false, user: null };
    }
    case 'REGISTER': {
      const { user } = action.payload;
      return { ...state, isAuthenticated: true, user };
    }
    default: {
      return state;
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: 'JWT',
  logout: () => {},
  login: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

export const AuthProvider = ({ children }) => {
  const [ state, dispatch ] = useReducer(reducer, initialState);

  const profile = async () => {
    const { data } = await api.get(`/Security/Profile`);

    localStorage.setItem('zeusGruposAtribuidos', JSON.stringify(data.gruposAtribuidos));

    return data;
  };

  const signIn = async (email, password) => {
    const { data } = await api.post('/Security/SignIn', {
      email: email,
      senha: password
    });
    const { accessToken } = data;
    setSession(accessToken);
    const user = await profile();    
    dispatch({ type: 'LOGIN', payload: { user } });
  };

  const signUp = async (username, email, password) => {
    const body = {
      nome: username,
      email: email,
      senha: password,
      status: 'Ativo'
    }
    const { data } = await api.post('/Security/SigUp', body);
    const { accessToken } = data;
    const { userId } = jwtDecode(accessToken);
    setSession(accessToken);
    const user = await profile(userId);
    dispatch({ type: 'REGISTER', payload: { user } });
  };

  const logout = () => {
    localStorage.removeItem('zeusGruposAtribuidos');
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  // console.log('jwt', state);

  useEffect(() => {
    (async () => {
      try {
        const accessToken = localStorage.getItem('accessToken');

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          const { userId } = jwtDecode(accessToken);
          const user = await profile(userId);
          dispatch({
            type: 'INIT',
            payload: { isAuthenticated: true, user },
          });
        } else {
          dispatch({
            type: 'INIT',
            payload: { isAuthenticated: false, user: null },
          });
        }
      } catch (err) {
        dispatch({
          type: 'INIT',
          payload: { isAuthenticated: false, user: null },
        });
      }
    })();
  }, []);

  if (!state.isInitialised) {
    return <MatxLoading />;
  }

  return (
    <AuthContext.Provider value={{ ...state, method: 'JWT', signIn, logout, signUp }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
